import * as React from "react"
import { RouteComponentProps } from "@gatsbyjs/reach-router"
import { navigate } from "gatsby"
import * as qs from "query-string"
import {
  StepIndicator,
  StepIndicatorStep,
  ThemeCss,
  StepIndicatorStepProps,
} from "gatsby-interface"
import { importSite as importSiteText } from "@modules/locales/default.js"
import { getUserId } from "@modules/analytics/utils"
import { PickRepository } from "@modules/onboarding/createFromExisting/components/PickRepository"
import { CmsIntegrationsStep } from "@modules/site/create/shared/components/CmsIntegrationsStep"
import {
  getPathToImportSite,
  getPathToCreateSite,
  isConnectedWithGithub,
} from "@modules/site/create/shared/utils"
import { getPathToOrgDetails } from "@modules/organization/shared/utils"
import { SiteCreationWizardHeader } from "@modules/site/create/shared/components/SiteCreationWizardHeader"
import SimpleStepIndicator from "@modules/site/create/shared/components/SimpleStepIndicator"
import { SourceControlProvider, CmsVendor } from "@modules/graphql/types"
import { SourceProviderSelector } from "@modules/site/create/ShopifyApp/Import/SourceProviderSelector"
import { SetupSiteStep } from "@modules/site/create/shared/components/SetupSiteStep"
import { LoginOption } from "@modules/user/shared/constants"
import { processSignupAttempt } from "@modules/auth/utils"
import { getPathToSiteDetails } from "@modules/site/details/utils"
import { useAvailableSourceProvidersQuery } from "@modules/site/create/Import/queries.generated"
import { useSetShopifyAppIntegration } from "@modules/cms/shared/hooks/useSetShopifyApp"
import { useFlags } from "@modules/featureFlags"

const siteRootCss: ThemeCss = theme => ({
  paddingBottom: "3rem",

  [theme.mediaQueries.phablet]: {
    paddingBottom: "7.5rem",
  },
})

const mapProviderToLoginOption: Record<SourceControlProvider, LoginOption> = {
  [SourceControlProvider.Github]: LoginOption.Github,
  [SourceControlProvider.GithubEnterprise]: LoginOption.GithubEnterprise,
  [SourceControlProvider.GitlabSelfManaged]: LoginOption.GitlabSelfManaged,
  [SourceControlProvider.Gitlab]: LoginOption.Gitlab,
  [SourceControlProvider.Bitbucket]: LoginOption.Bitbucket,
  [SourceControlProvider.Google]: LoginOption.Google,
  [SourceControlProvider.Workos]: LoginOption.Workos,
}

enum ImportSiteWizardStep {
  SourceProvider = `sourceProvider`,
  Repository = `repository`,
  Integrations = `integrations`,
  Setup = `setup`,
}

const stepLabels: Record<ImportSiteWizardStep, string> = {
  [ImportSiteWizardStep.SourceProvider]:
    importSiteText.labels.sourceProviderStep,
  [ImportSiteWizardStep.Repository]: importSiteText.labels.repositoryStep,
  [ImportSiteWizardStep.Integrations]: importSiteText.labels.integrationsStep,
  [ImportSiteWizardStep.Setup]: importSiteText.labels.setupStep,
}

export type ShopifyAppImportSiteWizardProps = RouteComponentProps<{
  organizationId: string
  siteId: string
  frameable?: boolean
  rootPath?: `dashboard` | `frameable`
  frameableCmsVendor?: CmsVendor
}>

type StepInfo = {
  step: ImportSiteWizardStep
  label: string
  to: string
  status: StepIndicatorStepProps["status"]
}

export function ShopifyAppImportSiteWizard({
  organizationId,
  siteId,
  location,
  frameable,
  frameableCmsVendor,
  rootPath = `dashboard`,
}: ShopifyAppImportSiteWizardProps) {
  const {
    activeStep,
    activeStepIndex,
    selectedSourceProvider,
    steps,
  } = useImportSiteWizardSteps({ organizationId, siteId, location, frameable })

  const { data: providersData } = useAvailableSourceProvidersQuery()

  const { clearShopifyStorageInfo } = useSetShopifyAppIntegration()

  const { flags } = useFlags()

  const connectedWithGithub = isConnectedWithGithub(
    providersData?.availableSourceProviders
  )

  if (!organizationId) {
    return null
  }

  const sourceProviderSelectorOnGoBackPath = connectedWithGithub
    ? getPathToCreateSite(organizationId)
    : getPathToOrgDetails(organizationId)

  return (
    <main css={siteRootCss}>
      <SiteCreationWizardHeader organizationId={organizationId} />
      {/* START EXPERIMENT */}
      {flags.simplifiedSiteStepIndicator ? (
        <SimpleStepIndicator
          activeStepNumber={activeStepIndex + 1}
          totalNumberOfSteps={steps.length}
        />
      ) : (
        <StepIndicator>
          {steps.map(step => (
            <StepIndicatorStep
              key={step.step}
              to={step.to}
              status={step.status}
            >
              {step.label}
            </StepIndicatorStep>
          ))}
        </StepIndicator>
      )}
      {/* END EXPERIMENT */}
      {activeStep === ImportSiteWizardStep.SourceProvider && (
        <SourceProviderSelector
          availableSourceProviders={providersData?.availableSourceProviders}
          onSelect={sourceProvider => {
            navigate(
              getPathToRepositoryStep(organizationId, sourceProvider, rootPath)
            )
          }}
          onGoBack={() => {
            if (frameable) {
              navigate(-1)
            } else {
              navigate(sourceProviderSelectorOnGoBackPath)
            }
          }}
          onAddProvider={sourceProvider => {
            processSignupAttempt(
              mapProviderToLoginOption[sourceProvider],
              {
                csrfToken: getUserId(),
                attachProvider: true,
                errorRedirectUrl: `${getPathToImportSite(
                  organizationId
                )}/repository`,
                loginRedirectUrl: getPathToRepositoryStep(
                  organizationId,
                  sourceProvider,
                  rootPath
                ),
              },
              {}
            )
          }}
        />
      )}
      {activeStep === ImportSiteWizardStep.Repository && (
        <PickRepository
          selectRepo={(siteId: string) =>
            navigate(
              frameable
                ? `${getPathToImportSite(
                    organizationId,
                    rootPath
                  )}/${siteId}/setup`
                : `${getPathToImportSite(
                    organizationId,
                    rootPath
                  )}/${siteId}/integrations`
            )
          }
          frameable={frameable}
          frameableCmsVendor={frameableCmsVendor}
          organizationId={organizationId}
          provider={selectedSourceProvider || undefined}
        />
      )}
      {activeStep === ImportSiteWizardStep.Integrations && siteId && (
        <CmsIntegrationsStep
          siteId={siteId}
          orgSitePath={getPathToImportSite(organizationId, rootPath)}
        />
      )}
      {activeStep === ImportSiteWizardStep.Setup && siteId && (
        <SetupSiteStep
          organizationId={organizationId}
          siteId={siteId}
          onPublished={() => {
            clearShopifyStorageInfo()

            frameable
              ? window.close()
              : navigate(
                  `${getPathToSiteDetails(siteId, organizationId)}?newsite=true`
                )
          }}
          onGoBack={() => {
            if (frameable) {
              navigate(-1)
            } else {
              navigate(
                `${getPathToImportSite(
                  organizationId,
                  rootPath
                )}/${siteId}/integrations`
              )
            }
          }}
        />
      )}
    </main>
  )
}

function useImportSiteWizardSteps({
  siteId,
  location,
  frameable,
}: ShopifyAppImportSiteWizardProps): {
  activeStep: ImportSiteWizardStep
  activeStepIndex: number
  selectedSourceProvider: SourceControlProvider | null
  steps: StepInfo[]
} {
  const searchParams = qs.parse(location?.search || ``)
  const selectedSourceProvider =
    (searchParams.sourceProvider as SourceControlProvider) || null
  const isOnIntegrationStep = location?.pathname?.includes(`integrations`)

  const steps: ImportSiteWizardStep[] = [
    ImportSiteWizardStep.SourceProvider,
    ImportSiteWizardStep.Repository,
    ImportSiteWizardStep.Integrations,
    ImportSiteWizardStep.Setup,
  ]

  let activeStep: ImportSiteWizardStep
  if (siteId) {
    activeStep = isOnIntegrationStep
      ? ImportSiteWizardStep.Integrations
      : ImportSiteWizardStep.Setup
  } else {
    activeStep = !selectedSourceProvider
      ? ImportSiteWizardStep.SourceProvider
      : ImportSiteWizardStep.Repository
  }
  const activeStepIndex = steps.indexOf(activeStep)

  return {
    activeStep,
    activeStepIndex,
    selectedSourceProvider,
    steps: steps
      .filter(stepName =>
        frameable
          ? ![ImportSiteWizardStep.Integrations].includes(stepName)
          : true
      )
      .map((step, stepIndex) => {
        return {
          step,
          label: stepLabels[step],
          status:
            activeStep === step
              ? `ACTIVE`
              : stepIndex < activeStepIndex
              ? `DONE`
              : `DEFAULT`,
          to: ``,
        }
      }),
  }
}

function getPathToWizardStep(
  organizationId: string,
  step: ImportSiteWizardStep,
  siteId?: string,
  rootPath?: string
) {
  return `${getPathToImportSite(organizationId, rootPath)}/${
    siteId ? `${siteId}/` : ``
  }${step}`
}

function getPathToRepositoryStep(
  organizationId: string,
  sourceProvider: SourceControlProvider,
  rootPath: string
) {
  return `${getPathToWizardStep(
    organizationId,
    ImportSiteWizardStep.Repository,
    undefined,
    rootPath
  )}?sourceProvider=${sourceProvider}`
}
